import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Router, useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import MobileNavToggle from "../components/MobileNavToggle";
import Socials from "../components/Socials";
import OathenGG from "../assets/imgs/oathengg.JPG";
import TwitterAPI from "../assets/imgs/twitter-api.JPG";
import { Link } from "react-router-dom";

const StyledMain = styled.main`
    margin-block: 3rem;

    h1 {
        margin-bottom: 1rem;
        text-decoration: underline;
    }

    h2 {
        border-left: 1px solid darkorange;
        border-top: 1px solid darkorange;
        border-right: 1px solid darkorange;
        padding-block: 0.5rem 0.5rem;
        margin-block: 2rem 0;
    }

    .projects img {
        border-radius: 0;
        width: 100%;
        border-top: 1px solid darkorange;
        border-left: 1px solid darkorange;
        border-bottom: 1px solid darkorange;
        border-right: 1px solid darkorange;
    }

    ul {
        display: inline-block;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: silver;
        margin-block: 0.5rem 0;
    }

    ul li {
        display: inline-block;
        font-size: 2.6em;
        padding-inline: 0.75rem;
        line-height: 1.5em;
    }

    .project-type p {
        text-align: left;
    }

    .project-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-block: -5px 1rem;
        background-color: darkorange;
    }

    .project-links a {
        width: 49.5%;
        border-radius: 0;
    }

    .projects .unavailable {
        background-color: #202020;
        cursor: not-allowed;
        outline: 1px solid #161616;
    }

    @media (min-width: 40em) {
        display: block;
        margin-block: 5rem;

        .project-type {
            max-width: 87.5%;
            margin: 0 auto 1rem;
        }

        .projects {
            margin: 0 auto 2rem;
        }

        .projects section {
            display: grid;
            grid-template-columns: 1fr 12vw 36vw;
            grid-template-rows: auto 1fr 2.2em;
            max-width: 87.5%;
        }

        .projects h2 {
            grid-row: 1;
            grid-column: 1 / 4;
            border: 0;
            padding-block: 0.5rem; 
        }

        .projects img {
            grid-column: 1;
            grid-row: 2 / 4;
            margin-bottom: 0;
            border: 0;
        }

        .projects p {
            grid-column: 2 / 4;
            grid-row: 2;
            padding: 0 1rem 1rem;
        }

        .projects .project-links {
            grid-column: 2;
            grid-row: 3;
            text-align: left;
            display: flex;
            padding-left: 10px;
            background-color: unset;
        }

        .projects a {
            margin: 0.4vw;
            /* display: inline-block; */
            padding: 0.4vw 0.6vw;
            height: 2em;
            text-align: center;
            width: 6em;
            border-radius: 0.5em;
        }

        .logo {
            z-index: 2000;
            position: absolute;
            font-size: 4vw;
            top: 1.2rem;
            left: 25%;
            transform: translateX(-50%);
            padding: 1vw 2vw
        }

        ul {
            grid-row: 3;
            grid-column: 3;
            justify-content: flex-start;
            margin-block: auto;
        }

        ul li {
            font-size: 2em;
        }
    }
`

const LogoLink = styled(Link)`
    color: orange;
    font-family: 'Caveat', 'Work Sans', sans-serif;
    text-decoration: none;
`

export default function Portfolio() {
    return (
        <div className="portfolio-page">
            <div class="name logo"><LogoLink to="../">Jason Luedeking</LogoLink></div>
            <MobileNavToggle />
            <Nav />
            <StyledMain>
                <div class="project-type">
                    <h1>Portfolio</h1>
                    <p>I've completed a number of group projects to gain experience in working as part of a team. These projects have ranged from back end APIs to full stack applications.</p>
                </div>
                <div class="projects">
                    <section>
                        <h2>Better Grocery List</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a href="http://oathen.gg" target="_new">Link</a>
                            <a className="unavailable">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-java-plain-wordmark"></i></li>
                            <li><i class="devicon-spring-plain-wordmark"></i></li>
                            <li><i class="devicon-postgresql-plain-wordmark"></i></li>
                            <li><i class="devicon-javascript-plain"></i></li>
                            <li><i class="devicon-react-original-wordmark"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-bootstrap-plain-wordmark"></i></li>
                        </ul>
                    </section>
                </div>
                <div className="projects">
                    <section>
                        <h2>OpenPixel</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a href="http://oathen.gg" target="_new" class="button-link">Link</a>
                            <a className="unavailable">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-java-plain-wordmark"></i></li>
                            <li><i class="devicon-spring-plain-wordmark"></i></li>
                            <li><i class="devicon-postgresql-plain-wordmark"></i></li>
                            <li><i class="devicon-javascript-plain"></i></li>
                            <li><i class="devicon-react-original-wordmark"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-tailwindcss-plain"></i></li>
                        </ul>
                    </section>
                </div>
                <div class="projects">
                    <section>
                        <h2>Company Management System</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a href="http://oathen.gg" target="_new" class="button-link">Link</a>
                            <a href="http://oathen.gg" target="_new" class="button-link">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-java-plain-wordmark"></i></li>
                            <li><i class="devicon-spring-plain-wordmark"></i></li>
                            <li><i class="devicon-postgresql-plain-wordmark"></i></li>
                            <li><i class="devicon-javascript-plain"></i></li>
                            <li><i class="devicon-react-original-wordmark"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-css3-plain-wordmark"></i></li>
                            <li><i class="devicon-figma-plain"></i></li>
                        </ul>
                    </section>
                </div>
                <div class="projects">
                    <section>
                        <h2>Social Media API</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a className="unavailable">Link</a>
                            <a href="https://github.com/JLuedeking/Twitter-API" target="_new" class="button-link">Github</a>
                        </div>
                        <p>Group project with two other teammates developing a Twitter-like RESTful web API. The project infrastructure used three layer onion architecture with repositories, mappers, and services, as well as exception handling. I was responsible for all aspects of the application dealing with the Tweet feature, including infrastructure setup of the entity, mapper, DTOs, services, controller, and end points managing tweets and various relational entities contained within a tweet. Created a parsing method for tweet string messages that converted hashtags and user mentions into entities, saved them to list fields within the tweet, verified their statuses within the repository, and saved any non-existent hashtag entities to the repository.</p>
                        <ul>
                            <li><i class="devicon-java-plain-wordmark"></i></li>
                            <li><i class="devicon-spring-plain-wordmark"></i></li>
                            <li><i class="devicon-postgresql-plain-wordmark"></i></li>
                            <li><i class="devicon-markdown-original"></i></li>
                        </ul>
                    </section>
                </div>
                <div class="projects">
                    <section>
                        <h2>Oathen.gg</h2>
                        <img src={OathenGG} />
                        <div className="project-links">
                            <a href="http://oathen.gg" target="_new" class="button-link">Link</a>
                            <a className="unavailable">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-csharp-plain-wordmark"></i></li>
                            <li><i class="devicon-dotnetcore-plain"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-css3-plain-wordmark"></i></li>
                            <li><i class="devicon-jquery-plain-wordmark"></i></li>
                            <li><i class="devicon-photoshop-line"></i></li>
                            <li><i class="devicon-amazonwebservices-plain-wordmark"></i></li>
                        </ul>
                    </section>
                </div>
                <div class="projects">
                    <section>
                        <h2>Guess The Artist</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a href="https://github.com/JLuedeking/Twitter-API" target="_new" class="button-link">Link</a>
                            <a href="https://github.com/JLuedeking/Twitter-API" target="_new" class="button-link">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-javascript-plain"></i></li>
                            <li><i class="devicon-react-original-wordmark"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-css3-plain-wordmark"></i></li>
                        </ul>
                    </section>
                </div>
                <div class="projects">
                    <section>
                        <h2>imyour.dev</h2>
                        <img src={TwitterAPI} />
                        <div className="project-links">
                            <a href="https://github.com/JLuedeking/Twitter-API" target="_new" class="button-link">Link</a>
                            <a href="https://github.com/JLuedeking/Twitter-API" target="_new" class="button-link">Github</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu scelerisque tortor. Morbi dictum nulla libero, ac tristique velit posuere dapibus. Suspendisse ligula magna, luctus vitae diam a, elementum ullamcorper mi. Maecenas et nunc purus. Curabitur porta euismod metus, a aliquam urna. Etiam ut dolor molestie, varius nulla aliquet, maximus urna. Donec eget mi et justo tempus blandit. In luctus, arcu vitae dictum fermentum, diam ante congue magna, eu dapibus odio tortor vel quam. Fusce vitae hendrerit odio. In a quam ullamcorper, lacinia nunc nec, placerat justo.</p>
                        <ul>
                            <li><i class="devicon-javascript-plain"></i></li>
                            <li><i class="devicon-react-original-wordmark"></i></li>
                            <li><i class="devicon-html5-plain-wordmark"></i></li>
                            <li><i class="devicon-css3-plain-wordmark"></i></li>
                        </ul>
                    </section>
                </div>
            </StyledMain>
            <Socials />
            <p>Ⓒ Jason Luedeking 2022</p>
        </div>
    );
}