import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import OpenHamburger from "../assets/imgs/hamburger.svg";
import CloseHamburger from "../assets/imgs/close.svg";

const StyledNav = styled.nav`
    .mobile-nav-toggle {
        display: none;
    }

    .primary-navigation {
        margin-block: 0.5em;
        background: hsl(0 0% 0% / 0.95);
    }
    /* If the browser supports blur, use this instead */
    @supports (backdrop-filter: blur(0.2rem)) {
        .primary-navigation {
            background: hsl(0 0% 0% / 0.8);
            backdrop-filter: blur(0.2rem);
        }
    }

    a {
        text-decoration: none;
        font-weight: 900;
        color: orange;
    }
    a:active {
        color: orangered;
    }
    a:hover {
        color: yellow;
    }

    ul li {
        align-items: center;
        justify-content: space-between;
        display: inline-block;
        list-style: none;
        font-weight: 700;
    }

    @media (max-width: 35em) {
        .primary-navigation {
            position: fixed;
            z-index: 1000;
            inset: 0 0 0 0%;
            padding: min(7.5vh, 3rem) 2em;
            transform: translateX(100%);
            transition: transform 350ms ease-out;
        }

        .primary-navigation[data-visible="true"] {
            transform: translateX(0%);
        }
        
        ul li {
            display: block;
            margin-block: 10px;
        }

        .mobile-nav-toggle {
        display: block;
        position: absolute;
        z-index: 9999;
        background-color: transparent;
        background-image: url(${ OpenHamburger });
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        width: 2rem;
        height: 2rem;
        top: 1.5rem;
        right: 1.5rem;
    }

        .mobile-nav-toggle[aria-expanded="true"] {
            background-image: url(${ CloseHamburger });
        }
    }

    @media (min-width: 40em) {
        padding-inline: 1.5em;
        margin-bottom: 1.7em;
        
        ul {
            text-align: right;
            display: block;
            font-size: 1vw;
        }

        ul li {
            width: 7vw;
        }
    }
`

const Nav = () => {
    return (
        <StyledNav>
            <button className="mobile-nav-toggle" aria-controls="primary-navigation" aria-expanded="false"></button>
            <ul id="primary-navigation" className="primary-navigation" data-visible="false">
                <li><Link to="../">ABOUT</Link></li>
                <li><Link to="../portfolio">PORTFOLIO</Link></li>
                <li><Link to="../contact">CONTACT</Link></li>
            </ul>
        </StyledNav>
    )
}

export default Nav;