import React from "react";
import styled from "styled-components";
import LinkedInLogo from "../assets/imgs/linkedin.png";
import GitHubLogo from "../assets/imgs/github.png";
import TwitterLogo from "../assets/imgs/twitter.png";
import EmailLogo from "../assets/imgs/email.png";

const StyledSocials = styled.ul`
    li {
        display: inline-block;
    }

    img {
        width: 2.5rem;
        height: 2.5rem;
        margin-inline: 0.6rem;
    }
`

const Socials = () => {
    return (
        <StyledSocials>
            <li><a href="https://www.linkedin.com/in/jason-luedeking-7b961712/" target="_blank"><img src={ LinkedInLogo } /></a></li>
            <li><a href="https://github.com/JLuedeking" target="_blank"><img src={ GitHubLogo } /></a></li>
            <li><a href="https://twitter.com/NatCritRoll" target="_blank"><img src={ TwitterLogo } /></a></li>
            <li><a href="mailto:jluedeking@gmail.com" target="_blank"><img src={ EmailLogo } /></a></li>
        </StyledSocials>
    )
}

export default Socials;

