import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Router, useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import MobileNavToggle from "../components/MobileNavToggle";
import ProfileImage from "../assets/imgs/jason.jpg";
import Socials from "../components/Socials";
import Resume from "../assets/files/FastTrack-Jason-Luedeking.pdf";

const StyledMain = styled.main`

    section {
        max-width: 60rem;
        margin-inline: auto;
    }

    section p {
        text-align: left;
        text-justify: unset;
        hyphens: unset;
    }

    .resume {
        padding: 0.75rem 1rem;
        background-color: orange;
        border-radius: 10px;
        text-decoration: none;
        font-weight: bolder;
        display: inline-block;
        width: auto;
        margin-block: 2rem 1.5rem;
    }

    img {
        width: 78%;
        border-radius: 100%;
        border: 5px solid orange;
        margin: 0 0 0 auto;
    }

    .about-links img {
        width: 2rem;
        height: 2rem;
        border: unset;
        border-radius: unset;
    }

    .about-links ul li {
        display: inline-block;
        margin-inline: 0.5rem;
    }

    .about-links ul li a {
        display: inline;
        background-color: unset;
        padding: 0;
    }

    @media (min-width: 40em) {

        .name {
            color: orange;
            font-family: 'Caveat', 'Work Sans', sans-serif;
        }

        section {
            padding-inline: 0.5rem;
        }

        /* Might not be needed? */
        section a {
            width: auto;
            text-align: center;
        }

        .resume {
            padding-block: 0.45rem;
            margin-bottom: 0.3rem;
        }

        .about-links {
            display: flex;
            align-items: flex-end;
        }

        .about-links ul {
            margin-left: 1.3rem;
        }
        
        .about-links ul li a {
            display: block;
        }

        .about-links img {
            width: 2.3rem;
            height: 2.3rem;
        }
    }

`

export default function About() {
    return (
        <div className="about-page">
            <MobileNavToggle />
            <Nav />
            <StyledMain>
                <img src={ ProfileImage } />
                <section className="verticle-center">
                    <h1 className="name">Jason Luedeking</h1>
                    <h2>Software Developer</h2>
                    <p>I'm a self taught developer with reinforced knowledge through both an intensive coding bootcamp and the Fast Track Java Developer program. I have a history of working in and alongside web development companies for the majority of my life, which has given me insight and experience into the team cooperation and communication needed by modern day developers. With several years in a senior level management roles, I'm adept at handling higher level responsibilities, as well as managing individuals through team wide goals. I also provide a flexible, determined approach to web development, with experience in positions that promote out of the box thinking and rapid adaptation to changing environments while also remaining focused on the overall objective.</p>
                    <div className="about-links">
                        <a href={ Resume } target="_blank" className="resume">Download Resume</a>
                        <Socials />
                    </div>
                </section>
            </StyledMain>
        </div>
    );
}