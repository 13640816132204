import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Router, useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import MobileNavToggle from "../components/MobileNavToggle";
import Socials from "../components/Socials";
import { Link } from "react-router-dom";

const StyledMain = styled.main`
    margin-block: 5rem 2rem;

    h1 {
        margin-bottom: 15px;
    }

    .bumpers {
        margin-bottom: 2em;
        padding-inline: 1.5em;
        text-align:justify;
        text-justify: inter-word;
    }

    input,
    textarea {
        padding: 5px;
        width: 100%;
        margin-bottom: 10px;
        font-family: 'Work Sans', sans-serif;
        box-sizing: border-box;
        border-radius: 10px;
    }

    input[type=submit] {
        background-color: orange;
        font-weight: 900;
        font-size: 5vw;
    }

    @media (min-width: 40em) {
        display: block;
        margin-block: 5rem 4rem;

        form {
            margin-top: 2rem;
            display: grid;
            grid-gap: 1vw;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        form input, form textarea {
            padding: 10px;
            border-radius: 10px;
            margin: 0;
            font-size: 1vw;
        }

        form input[name="name"] {
            grid-column: 1;
            grid-row: 1;
        }

        form input[name="email"] {
            grid-column: 2;
            grid-row: 1;
        }

        form input[name="phone"] {
            grid-column: 3;
            grid-row: 1;
        }

        form input[type="submit"] {
            grid-column: 3;
            grid-row: 2;
            font-size: 1vw;
        }

        form textarea {
            grid-column: 1 / 3;
            grid-row: 2 / 4;
        }

        .contact-page p a {
            color: orange;
            font-family: 'Caveat', 'Work Sans', sans-serif;
            text-decoration: none;
        }
    }
`

const LogoLink = styled(Link)`
    color: orange;
    font-family: 'Caveat', 'Work Sans', sans-serif;
    text-decoration: none;
`

export default function Contact() {
    return (
        <div className="contact-page">
            <p class="name logo"><LogoLink to="../">Jason Luedeking</LogoLink></p>
            <MobileNavToggle />
            <Nav />
            <StyledMain>
                <section>
                    <h1 class="contact-page">Contact Me</h1>
                    <form action="" method="">
                        <input type="text" name="name" placeholder="Full Name" />
                        <input type="text" name="email" placeholder="Email" />
                        <input type="tel" name="phone" placeholder="Phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                        <textarea name="message" rows="6" cols="50" placeholder="Message"></textarea>
                        <input type="submit" value="Submit" />
                    </form>
                </section>
            </StyledMain>
            <Socials />
        </div>
    )
}