import React from "react";
import styled from "styled-components";
import HamburgerIcon from "../assets/imgs/hamburger.svg";
import CloseNavIcon from "../assets/imgs/close.svg";

const StyledMobileNavToggle = styled.button`

    display: none;

    @media (max-width: 35em) {
        .mobile-nav-toggle {
            display: block;
            position: absolute;
            z-index: 9999;
            background-color: transparent;
            background-image: url('../imgs/hamburger.svg');
            background-repeat: no-repeat;
            background-size: contain;
            border: 0;
            width: 2rem;
            height: 2rem;
            top: 1.5rem;
            right: 1.8rem;
        }

        .mobile-nav-toggle[aria-expanded="true"] {
            background-image: url('../imgs/close.svg');
        }
    }
`

const MobileNavToggle = () => {
    return (
        <StyledMobileNavToggle 
            aria-controls="primary-navigation" 
            aria-expanded="false"
        >
        </StyledMobileNavToggle>
    )
}

export default MobileNavToggle;