import { Routes, Route } from "react-router-dom"
import About from "./screens/About";
import Portfolio from "./screens/Portfolio";
import Contact from "./screens/Contact";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
        /* outline: 1px dashed red; */
    }

    html {
        min-height: 100%;
    }

    body {
        min-height: 100%;
        background-color: black;
        color: white;
        font-size: 1.2rem;
        font-family: 'Work Sans', sans-serif;
        text-align: center;
        padding: 3rem 1rem;
        overflow: scroll;
        overflow-x: hidden;
    }

    h1,
    h2,
    h3 {
        color: orange;
        line-height: 1;
    }

    h1 {
        font-size: 3rem;
        margin-top: 1rem;
    }

    h2 {
        margin-bottom: 0.75em;
    }

    .name {
        color: orange;
        font-family: 'Caveat', 'Work Sans', sans-serif;
    }

    .logo {
        position: absolute;
        font-size: 2.3rem;
        width: 100%;
        top: 1.2rem;
        left: 50%;
        transform: translateX(-50%);
    }

    main img {
        width: 50%;
        /* margin: 20px; */
        border-radius: 100%;
        border: 5px solid orange;
    }

    section {
        max-width: 60rem;
        margin-inline: auto;
    }

    section p {
        text-align: left;
        text-justify: inter-word;
        hyphens: auto;
    }

    section a {
        padding: 0.6em 1.2em;
        background-color: orange;
        color: black;
        border-radius: 1em;
        text-decoration: none;
        font-weight: bolder;
        display: inline-block;
    }

    @media (min-width: 40em) {
        body {
            padding: 6rem 1rem 3rem;
        }

        main {
            margin-inline: auto;
            width: 80%;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-auto-flow: column;
        }

        main img {
            grid-column: 1;
            margin-inline: auto;
            width: 80%;
        }

        main section {
            grid-column: 2;
        }

        section {
            padding-inline: 0;
        }

        h1 {
            font-size: 3vw;
            text-align: left;
        }

        h2 {
            font-size: 2vw;
            text-align: left;
        }

        p, section a {
            font-size: 1vw;
        }

        .vertical-center {
            margin-block: auto;
        }

        section a {
            padding: 0.6em 1.2em;
            width: 8.8vw;
            text-align: center;
            font-size: 1vw;
            text-align: left;
            display: block;
        }

        section p {
            text-align: left;
            hyphens: unset;
        }

        .bottom-positioned {
            margin-inline: auto;
            padding-bottom: 0;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .logo {
            z-index: 2000;
            position: absolute;
            font-size: 4vw;
            top: 1.2rem;
            left: 25%;
            transform: translateX(-50%);
            padding: 1vw 2vw
        }
    }
`

export default function App() {
  return (
    <div className="App">
        <GlobalStyle />
        <Routes>
            <Route path="/" element={ <About/> } />
            <Route path="/portfolio" element={ <Portfolio/> } />
            <Route path="/contact" element={ <Contact/> } />
        </Routes>
    </div>
  );
}
